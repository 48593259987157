import React, { useRef, useEffect } from "react";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import { openNotification } from "../../helpers/openNotification";
import { apiUrlBase } from "../../data/urls";
import "./ImagePicker.css";
import { connect } from "react-redux";
import { AuthState } from "../../store/auth/types";

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize
);

interface Props {
  id: string;
  name: string;
  onChange: (files: any) => void;
  value?: string;
  onUploadComplete: (response: any) => void;
  className?: string;
  auth: AuthState;
}

const Uploader: React.FC<Props> = ({
  id,
  name,
  onChange,
  value,
  onUploadComplete,
  className,
  auth,
}) => {
  const pondRef = useRef<FilePond>(null);

  useEffect(() => {
    if (pondRef.current && value) {
      pondRef.current.addFile(value);
    }
  }, [value]);

  return (
    <div className="image-picker__filepond">
      <FilePond
        ref={pondRef}
        allowMultiple={false}
        credits={false}
        acceptedFileTypes={["image/*"]}
        labelFileTypeNotAllowed="Only images are allowed"
        maxFileSize="1MB"
        labelMaxFileSizeExceeded="File is too large"
        server={{
          url: `${apiUrlBase}/api/v1/images/user_upload_to_s3?file_key=${name}`,
          headers: {
            Authorization: `Token ${auth.accessToken}`,
          },
          // @ts-ignore
          process: {
            method: "POST",
            onload: (response) => {
              const responseData = JSON.parse(response).file;
              const uploadedImgSrc = responseData.url;
              if (uploadedImgSrc) {
                onUploadComplete(responseData);
              } else {
                openNotification(
                  "Error :(",
                  "There was an unknown error during uploading the file. Please refresh the page and try again.",
                  "OK",
                  "warn"
                );
              }
              return responseData;
            },
            onerror: (error) => {
              console.error("FilePond error:", error);
              let errorJson: any = {};
              let message = "There was an error during file upload. Please try again.";
              try {
                errorJson = JSON.parse(error);
                message = errorJson.message || errorJson.detail;
              } catch (e) {
                console.error("Error parsing error:", e);
              }
              openNotification(
                "Error",
                message,
                "OK",
                "error"
              );
            },
          },
        }}
        name={name}
        id={id}
        className={className}
        onupdatefiles={(fileItems) => {
          const files = fileItems.map((fileItem) => fileItem.file);
          onChange(files.length > 0 ? files[0] : null);
        }}
        labelIdle='Upload image'
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Uploader);

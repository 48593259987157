import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getCurrentStoreData } from "../../store/websites/thunks";
import {
  ChangeWebsiteItemGlobalData,
  ChangeWebsitesStateGlobalData,
  GetCurrentStoreData,
} from "../../store/websites/types";
import "./CmsSidebar.scss";
import { replaceDemoCollectionIds } from "../../store/websitePages/actions";
import {
  changeWebsiteItemGlobalData,
  changeWebsitesStateGlobalData,
} from "../../store/websites/actions";
import { AuthState } from "../../store/auth/types";

interface Props {
  auth: AuthState;
  getCurrentStoreData: GetCurrentStoreData;
  replaceDemoCollectionIds;
  changeWebsiteItemGlobalData: ChangeWebsiteItemGlobalData;
  changeWebsitesStateGlobalData: ChangeWebsitesStateGlobalData;
}

const CmsSidebar = (props: Props): JSX.Element => {
  const { getCurrentStoreData } = props;
  const { currentWebsite } = getCurrentStoreData();

  return (
    <div className="cms-sidebar">
      <iframe
        src={`${window.location.origin}/mars/${currentWebsite.id}/collections/`}
      ></iframe>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth,
  };
};
export default connect(mapStateToProps, {
  getCurrentStoreData,
  replaceDemoCollectionIds,
  changeWebsiteItemGlobalData,
  changeWebsitesStateGlobalData,
})(CmsSidebar);
